import { Component, OnInit, HostBinding } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';
import { PoiService } from '../poi/poi.service';
import { UserService } from '../users/user.service';
import { CitiesService } from '../cities/cities.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.css']
})
export class NavigatorComponent implements OnInit {

  @HostBinding('class') componentCssClass;

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  signedIn: Boolean;
  user: Object;
  username: string;
  password: string;

  menucollapsestatus = false;

  UserNotFoundException: Boolean = false;
  NotAuthorizedException: Boolean = false;

  constructor(
    public overlayContainer: OverlayContainer,
    private breakpointObserver: BreakpointObserver,
    private amplifyService: AmplifyService,
    private poiService: PoiService,
    private userService: UserService,
    private citiesService: CitiesService,
    private organizationsService: OrganizationsService,
    private snackbar: MatSnackBar,
    private router: Router) {
    let self = this;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;

        if (self.user && self.user["attributes"] && self.user["attributes"]["custom:preferencies"]) {
          let preferencies = JSON.parse(self.user["attributes"]["custom:preferencies"]);
          Object.keys(preferencies).forEach(key => {
            if (key == 'ui') {
              self.overlayContainer.getContainerElement().classList.add(preferencies[key]);
              self.componentCssClass = preferencies[key];
            } else {
              self.overlayContainer.getContainerElement().classList.add('light-theme');
              self.componentCssClass = 'light-theme';
            }
            if (key == 'menustatus') {
              if (preferencies[key] == "open") {
                self.menucollapsestatus = false;
              } else {
                self.menucollapsestatus = true;
              }
            }
          });
        }
      }
    });
  }

  ngOnInit() {
    let self = this;
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      var confirmationMessage = "\o/";
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    });
  }

  signOut() {
    let self = this;
    self.userService.signOut().subscribe(
      data => {
        self.snackbar.open('Bye bye', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
        self.user = null;
        window.location.reload(false);
      }
    );
  }

  signIn = function () {
    let self = this;

    /* self.userService.signUp(self.username, self.password, [], "1b93729a-2852-4410-b11f-17937dc70220")
      .then(data => { console.log(data) })
      .catch(err => { console.log(err) }); */

    self.userService.signIn(self.username, self.password).subscribe(
      data => {
        if (data) self.snackbar.open('Welcome ' + data.username + '!', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
      },
      error => {
        switch (error.code) {
          case 'UserNotFoundException':
            self.UserNotFoundException = true;
            break;
          case 'NotAuthorizedException':
            self.NotAuthorizedException = true;
          default:
            console.log(error);
            break;
        }
      });
  }

  resetErrors() {
    let self = this;
    self.UserNotFoundException = false;
    self.NotAuthorizedException = false;
  }

  collapseMenu() {
    this.menucollapsestatus = !this.menucollapsestatus;
  }

  onSetTheme(theme) {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }

}
