import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import { UserService } from '../users/user.service'

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(private amplifyService: AmplifyService, private userSevice: UserService) { }

  create = function (type, descriptions, flags, notes, user_create, date_create, user_assigned, date_assigned, poiMaintenancesPoiId) {
    const createPoiMaintenances = `mutation(
        $type: Int!, 
        $descriptions: [String], 
        $flags: [String], 
        $notes: [String], 
        $user_create: String!, 
        $date_create: AWSDateTime!, 
        $user_assigned: String!, 
        $date_assigned: AWSDateTime!, 
        $poiMaintenancesPoiId: ID!) {
      createPoiMaintenances(input: {
            type: $type
            descriptions: $descriptions
            flags: $flags
            notes: $notes
            user_create: $user_create
            date_create: $date_create
            user_assigned: $user_assigned
            date_assigned: $date_assigned
            poiMaintenancesPoiId: $poiMaintenancesPoiId
           }) {
             id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(createPoiMaintenances, {
      type: type,
      descriptions: descriptions,
      flags: flags,
      notes: notes,
      user_create: user_create,
      date_create: date_create,
      user_assigned: user_assigned,
      date_assigned: date_assigned,
      poiMaintenancesPoiId: poiMaintenancesPoiId
    }));
  }

  createAction = function (type, status, level, description, info, user, date, poiMaintenancesId, planneddate?) {
    const createPoiMaintenancesActions = `mutation($type: Int!, $status: Int!, $level: Int, $description: String, $info: AWSJSON, $user: String!, $date: AWSDateTime!, $poiMaintenancesActionsMaintenanceId: ID, 
      `+ ((planneddate) ? `$planneddate: AWSDateTime` : ``) + `) {
      createPoiMaintenancesActions(input: {
            type: $type
            status: $status
            level: $level
            description: $description
            info: $info
            user: $user
            date: $date
            poiMaintenancesActionsMaintenanceId: $poiMaintenancesActionsMaintenanceId
            `+ ((planneddate) ? `planneddate: $planneddate` : ``) + `
           }) {
             id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(createPoiMaintenancesActions, {
      type: parseInt(type),
      status: parseInt(status),
      level: parseInt(level),
      description: description,
      info: JSON.stringify(info),
      user: user,
      date: date,
      poiMaintenancesActionsMaintenanceId: poiMaintenancesId,
      planneddate: planneddate
    }));
  }


}
