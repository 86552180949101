import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ImportsService } from '../imports/imports.service';
import Amplify, { Storage } from 'aws-amplify';
import { PoiService } from '../poi/poi.service';
import { UserService } from '../users/user.service';
import { CitiesService } from '../cities/cities.service';
import { v4 as uuid } from 'uuid';
import { ActivityType, ActivityOperation, ActivitiesService, ActivityDetail } from '../activities.service';
import { WorksService } from '../works/works.service';

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.css']
})
export class ImportsComponent {

  private cities = [];
  private layers = [];
  private user;
  private currentCity = null;
  private currentLayer = null;
  private currentPoitype = null;
  private currentUser = null;
  private currentFile = null;
  private rows = [];
  private orginalrows = [];
  private separators = [";", ",", "|"];
  private currentSeparator = ",";
  private poitypes = [];
  private columnsMapper = [];
  private columnsDataMapper = [];
  private ignorefirstrow = false;
  private ignorequotes = false;
  private stringvalues = false;

  constructor(
    private importsService: ImportsService,
    private userService: UserService,
    private citiesService: CitiesService,
    private poiService: PoiService,
    private workService: WorksService,
    private snackbar: MatSnackBar,
    private activities: ActivitiesService) {
    let self = this;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        self.cities = self.user.activecities.sort((a, b) => a.name.toString().toLowerCase().localeCompare(b.name.toString().toLowerCase()));
        Object.keys(self.user.organizationObject.poiTypes).forEach(key => {
          self.poiService.getType(self.user.organizationObject.poiTypes[key]).then(poiType => {
            self.poitypes.push(poiType.data.getPoiType);
          }).catch(err => {
            console.log(err);
          });
        });
      }
    });
  }

  private elaborateRows() {
    let self = this;
    if (self.currentSeparator && self.orginalrows && self.orginalrows.length > 0) {
      self.rows = [];
      var rowcounter = 0;
      self.orginalrows.forEach(originalrow => {
        if (self.ignorefirstrow && rowcounter++ == 0) return;
        if (self.ignorequotes) self.rows.push(originalrow.toString().replace(/\"/g, '').split(self.currentSeparator));
        else self.rows.push(originalrow.toString().split(self.currentSeparator));
      });
    }
  }

  private changeIgnoreFirstRow(event) {
    let self = this;
    self.ignorefirstrow = event.checked;
    return self.elaborateRows();
  }

  private changeIgnoreQuotes(event) {
    let self = this;
    self.ignorequotes = event.checked;
    return self.elaborateRows();
  }

  private changeStringValues(event) {
    let self = this;
    self.stringvalues = event.checked;
    return;
  }

  private changePoitype(event) {
    let self = this, ignoreInserted = false, latitudeInserted = false, longitudeInserted = false;
    if (event && event.value) {
      self.currentPoitype = event.value;
      var structure_sections = JSON.parse(event.value.structure);
      self.columnsDataMapper = [];
      Object.keys(structure_sections).forEach(function (key) {
        var structure_section = structure_sections[key];
        if (structure_section && structure_section.items) {
          var elements = structure_section.items;
          if (!ignoreInserted) {
            self.columnsDataMapper.push({ key: "ignore", value: "Ignore" });
            ignoreInserted = true;
          }
          Object.keys(elements).forEach(function (elementkey) {
            self.columnsDataMapper.push({ key: elementkey, value: elements[elementkey].label });
          });
          if (!latitudeInserted) {
            self.columnsDataMapper.push({ key: "position_lat", value: "Latitude" });
            latitudeInserted = true;
          }
          if (!longitudeInserted) {
            self.columnsDataMapper.push({ key: "position_lng", value: "Longitude" });
            longitudeInserted = true;
          }
        }
      });
    }
  }

  private changeSeparator(separator) {
    let self = this;
    self.currentSeparator = separator.value;
    var columnsLen = self.orginalrows[0].toString().split(self.currentSeparator);
    self.columnsMapper = self.initColumnsMapper(columnsLen);
    return self.elaborateRows();
  }

  private changeCity(city) {
    let self = this;
    self.currentCity = (city.value) ? city.value : null;

    if (self.currentCity) {
      self.citiesService.getLayersFromCity(self.currentCity.id, self.user.organization)
        .then(data => {
          if (data && data.data && data.data.getCities && data.data.getCities.layers && data.data.getCities.layers.items)
            self.layers = data.data.getCities.layers.items;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private compareMappings(o1: any, o2: any): boolean {
    return o1.key === o2.key;
  }

  private changeLayer(layer) {
    let self = this;
    self.currentLayer = (layer.value) ? layer.value : null;

  }

  private getDataMapper(key) {
    let self = this, retObj = null;
    for (var i = 0, leni = self.columnsDataMapper.length; i < leni; i++) {
      if (self.columnsDataMapper[i].key == key) {
        retObj = self.columnsDataMapper[i];
      }
    }
    return retObj;
  }

  private initColumnsMapper(firstrow) {
    let self = this, a = [];
    for (var i = 0, leni = firstrow.length; i < leni; i++) {
      var datamapper = self.getDataMapper(firstrow[i]);
      if (datamapper != null) a.push(datamapper);
      else a.push({ key: "ignore", value: "Ignore" });
    }
    return a;
  }

  private loadFileW(data) {
    let self = this;
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      self.currentFile = data.target.files[0];
      var metadata = [];
      metadata["city"] = self.currentCity.id.toString();
      metadata["layer"] = self.currentLayer.id.toString();
      metadata["user"] = self.user.username.toString();
      metadata["organization"] = self.user.organization.toString();

      var filename = "imports_works/" + uuid() + ".csv";

      Storage.put(filename, self.currentFile, {
        contentType: self.currentFile.type,
        metadata: metadata
      })
        .then(result => {
          self.workService.import(result["key"])
          .then(result => {
            self.snackbar.open('File correctly uploaded!', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
          })
          .catch(err => {
            console.log(err);
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
  
  private loadFileP(data) {
    let self = this;
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      self.currentFile = data.target.files[0];

      var reader = new FileReader();
      reader.onload = (function (reader) {
        return function () {
          var contents = reader.result.toString();
          var rows = contents.toString().split('\n').slice(0, 4);
          var rowcounter = 0;

          rows.forEach(row => {
            var columns = row.toString().split(self.currentSeparator);
            if (rowcounter == 0 && columns && columns.length > 0) {
              self.columnsMapper = self.initColumnsMapper(columns);
            }

            self.orginalrows.push(row.toString());
            if (self.ignorefirstrow && rowcounter == 0) return;
            rowcounter++;
            self.rows.push(columns);
          });
        }
      })(reader);

      reader.readAsText(data.target.files[0]);
    }
  }

  private columnschange(test) {
    console.log(test);
  }

  private import() {
    let self = this;

    if (self.currentFile && self.currentCity && self.currentCity.id && self.user) {
      var metadata = [];
      metadata["city"] = self.currentCity.id.toString();
      metadata["layer"] = self.currentLayer.id.toString();
      metadata["poitype"] = self.currentPoitype.id.toString();
      metadata["user"] = self.user.username.toString();
      metadata["organization"] = self.user.organization.toString();

      var purgedColumnsMapper = [];
      self.columnsMapper.forEach(columnMapper => {
        purgedColumnsMapper.push(columnMapper.key);
      });
      metadata["mapping"] = JSON.stringify(purgedColumnsMapper);
      metadata["ignorefirstrow"] = self.ignorefirstrow.toString();
      metadata["stringvalues"] = self.stringvalues.toString();
      metadata["ignorequotes"] = self.ignorequotes.toString();
      metadata["separator"] = self.currentSeparator.toString();

      var filename = "imports/" + uuid() + ".csv";

      Storage.put(filename, self.currentFile, {
        contentType: self.currentFile.type,
        metadata: metadata
      })
        .then(result => {
          self.snackbar.open('Upload correctly, import will insert all poi', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
        })
        .catch(err => {
          self.snackbar.open('An error occurs uploading', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
        });
    }
  }

}
