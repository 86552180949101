import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import Auth from '@aws-amplify/auth';
import { Observable } from 'rxjs';
import { City } from './city';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(
    private httpClient: HttpClient,
    private amplifyService: AmplifyService
  ) { }

  getAllPoiFromCityBounded = function (layer: string, organization: string, maxLatitude: number, maxLongitude: number, minLatitude: number, minLongitude: number, poiNextToken?: string) {
    let getCities = `
    query {
      getPoiLayer(id:"`+ layer + `") {
        id
        poi(
          filter:{
            and: [
              {
                organizationID: {
                  eq: "`+ organization + `"
                }
              },
              {
                position_lat: {
                  ge: `+ minLatitude + `
                }
              },
              {
                position_lng: {
                  ge: `+ minLongitude + `
                }
              },
              {
                position_lat: {
                  le: `+ maxLatitude + `
                }
              },
              {
                position_lng: {
                  le: `+ maxLongitude + `
                }
              }
            ]
          }, limit: 200,
          `+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `
        ) {
          items {
            id
            position_lat
            position_lng
            position_flr
            icon
            type {
              name
            }
            data
          }
          nextToken
        }   
      }
    }`;

    return this.amplifyService.api().graphql(graphqlOperation(getCities));
  }

  getAllPoiFromCity = function (layer: string, organization: string, poiNextToken?: string) {
    let getCities = `
    query {
      getPoiLayer(id:"`+ layer + `") {
        id
        poi(
          filter:{
            organizationID: {
              eq: "`+ organization + `"
            }
          }, limit: 200,
          `+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `
        ) {
          items {
            id
            position_lat
            position_lng
            position_flr
            icon
            type {
              name
            }
            data
          }
          nextToken
        }   
      }
    }`;

    return this.amplifyService.api().graphql(graphqlOperation(getCities));
  }

  getAllPoiFromCityNoData = function (layer: string, organization: string, poiNextToken?: string) {
    let getCities = `
    query {
      getPoiLayer(id:"`+ layer + `") {
        id
        poi(
          filter:{
            organizationID: {
              eq: "`+ organization + `"
            }
          }, limit: 200,
          `+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `
        ) {
          items {
            id
            position_lat
            position_lng
            position_flr
            icon
            type {
              name
            }
          }
          nextToken
        }   
      }
    }`;

    return this.amplifyService.api().graphql(graphqlOperation(getCities));
  }

  getAllPoiFromCityWithWorks = function (layer: string, organization: string, poiNextToken?: string) {
    let getCities = `
    query {
      getPoiLayer(id:"`+ layer + `") {
        id
        poi(
          filter:{
            organizationID: {
              eq: "`+ organization + `"
            }
          }, limit: 200,
          `+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `
        ) {
          items {
            id
            position_lat
            position_lng
            position_flr
            icon
            type {
              name
            }
            works {
              items {
                id
                data
              }
            }
          }
          nextToken
        }   
      }
    }`;

    return this.amplifyService.api().graphql(graphqlOperation(getCities));
  }

  getAllFromCity = function (id: string, organization: string, data?: boolean, poiNextToken?: string) {
    var getCities = "";
    if (!data) {
      getCities = `
          query {
            getCities(id:"`+ id + `") {
              id
              layers(filter:{
                organizationID: {
                  eq: "`+ organization + `"
                }
              }) {
                items {
                  id
                  name
                  color
                  poi(
                    filter:{
                      organizationID: {
                        eq: "`+ organization + `"
                      }
                    },
                    `+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `
                  ) {
                    items {
                      id
                      position_lat
                      position_lng
                      position_flr
                      icon
                      type {
                        name
                      }
                      data
                    }
                    nextToken
                  }
                }
              }
              name
              area
              info
            }
          }
        `;
    } else {
      getCities = `
              query {
                getCities(id:"`+ id + `") {
                  id
                  layers(filter:{
                    organizationID: {
                      eq: "`+ organization + `"
                    }
                  }) {
                    items {
                      id
                      name
                      color
                      poi(
                        filter:{
                          organizationID: {
                            eq: "`+ organization + `"
                          }
                        },
                        `+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `
                      ) {
                        items {
                          id
                          position_lat
                          position_lng
                          position_flr
                          icon
                          type {
                            name
                          }
                          data
                        }
                        nextToken
                      }
                    }
                  }
                  name
                  area
                  info
                }
              }
            `;
    }

    return this.amplifyService.api().graphql(graphqlOperation(getCities));
  }

  get = function (id) {
    const getCities = `
        query {
          getCities(id:"`+ id + `") {
            id
            name
            position_lat
            position_lng
            area
            info
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getCities));
  }

  getLayersFromCity = function (id, organization) {
    const getCities = `
        query {
          getCities(id:"`+ id + `") {
            id
            layers(filter:{
              organizationID: {
                eq: "`+ organization + `"
              }
            }) {
              items {
                id
                name
                color
                kmlkmz
              }
            }
            name
            area
            info
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getCities));
  }

  getPoisFromCity = function (id, poiNextToken?: string) {
    const getCities = `
        query {
          getCities(id:"`+ id + `") {
            id
            poi(`+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `){
              items {
                id
                position_lat
                position_lng
                position_flr
                icon
                type {
                  name
                }
                data
                nextToken
              }
            }
            name
            area
            info
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getCities));
  }

  create = function (name, lat, lng, flr, area, info, citiesOrganizationId) {
    const createCity = `
         mutation($position_lat: Float!, $position_lng: Float!, $position_flr: Float!, $name: String!, $area: AWSJSON, $info: AWSJSON, $citiesOrganizationId: ID, $citiesOrganization: String!) {
          createCities(input: {
            position_lat: $position_lat,
            position_lng: $position_lng,
            position_flr: $position_flr,
            name: $name,
            area: $area,
            info: $info,
            citiesOrganizationId: $citiesOrganizationId
            organizationID: $citiesOrganization
           }) {
             id
           }
         }
       `;

    if (info) {
      for (var k in info) {
        if (info.hasOwnProperty(k)) {
          if (!info[k] || typeof (info[k]) == 'undefined' || info[k] == null || info[k] === "")
            delete info[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(createCity, {
      position_lat: lat,
      position_lng: lng,
      position_flr: flr,
      name: name,
      area: JSON.stringify(area),
      info: JSON.stringify(info),
      citiesOrganizationId: citiesOrganizationId,
      citiesOrganization: citiesOrganizationId.toString()
    }));
  }

  delete = function (id) {
    const deleteCity = `
         mutation($id: ID!) {
          deleteCities(input: {
            id: $id
           }) {
            id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(deleteCity, {
      id: id
    }));
  }

  all = function () {
    const ListCities = `
        query {
          listCitiess {
            items {
              id
              position_lat
              position_lng
              position_flr
              name
              area
              info
            }
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(ListCities));
  }
  
  searchJSON = function(): Observable<City[]> {
    return this.httpClient.get('assets/cities/cities.json');
  }
  
  search = function (name): Observable<City[]> {
    if (name) {
      const searchCitiess = `
        query {
          searchCitiess(filter: {
            name: {
              wildcard: "`+ name.toString().toLowerCase() + `*"
            }
          }, limit: 40) {
            items {
              id
              name
              area
              info
              position_lat
              position_lng
            }
          }
        }
      `;
      return this.amplifyService.api().graphql(graphqlOperation(searchCitiess));
    } else return null;
  }

  deleteAllFromCity = function(type, city, layer){
    const deletePois = `
        query {
          deletePois(type:"`+ type + `", city:"`+ city + `", layer:"`+ layer + `")
        }
      `;
      return this.amplifyService.api().graphql(graphqlOperation(deletePois));
  }

  staticize = function(type, city, layer){
    const deletePois = `
        query {
          staticizePois(type:"`+ type + `", city:"`+ city + `", layer:"`+ layer + `")
        }
      `;
      return this.amplifyService.api().graphql(graphqlOperation(deletePois));
  }

  statistics = function(city, organization){
    const poiStatistics = `
        query {
          poiStatistics(city:"`+ city + `", organization:"`+ organization + `")
        }
      `;
      return this.amplifyService.api().graphql(graphqlOperation(poiStatistics));
  }
}
