import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import { Poi } from "./poi";
import { Organization } from '../organizations/organization';
import { UserService } from '../users/user.service'

@Injectable({
  providedIn: 'root'
})
export class PoiService {

  constructor(private amplifyService: AmplifyService, private userSevice: UserService) { }

  subscribe = function (cb) {
    const SubscribeToNewPoi = `subscription {
      onCreatePoi {
        id
      }
    }`;

    this.amplifyService.api().graphql(graphqlOperation(SubscribeToNewPoi)).subscribe(
      data => cb(data)
    );
  }

  getMinePoiTypeId = function () {
    return new Promise((resolve, reject) => {
      let self = this;
      self.userSevice.getCurrentUser()
        .then(user => {
          resolve(user.organizationObject.poiTypes);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  getType = function (id) {
    const getPoiType = `query {
                        getPoiType(id:"`+ id + `") {
                          id
                          name
                          structure
                          icon
                          infowindow
                        }
                      }
                    `;

    return this.amplifyService.api().graphql(graphqlOperation(getPoiType));
  }

  create = function (poicity, poilayer, poitype, lat, lng, flr, data, owner, organization, files, icon) {
    const CreatePoi = `mutation(
              $position_lat: Float!, 
              $position_lng: Float!, 
              $position_flr: Float!, 
              $createdAt: AWSDateTime, 
              $status: Int, 
              $data: AWSJSON, 
              $poiTypeId: ID!, 
              $poiLayerId: ID!, 
              $poiCityId: ID!, 
              $owner: String!, 
              $organization: ID!, 
              $organizationID: String!, 
              $files: [String], 
              $icon: String, 
              $shape: Int!) {
          createPoi(input: {
            position_lat: $position_lat,
            position_lng: $position_lng,
            position_flr: $position_flr,
            createdAt: $createdAt,
            status: $status,
            data: $data,
            poiTypeId: $poiTypeId,
            poiLayerId: $poiLayerId
            poiCityId: $poiCityId,
            owner: $owner,
            poiOrganizationId: $organization,
            organizationID: $organizationID,
            files: $files,
            icon: $icon,
            shape: $shape
           }) {
             id
           }
         }
       `;

    if (data) {
      for (var k in data) {
        if (data.hasOwnProperty(k)) {
          if (!data[k] || typeof (data[k]) == 'undefined' || data[k] == null || data[k] === "")
            delete data[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(CreatePoi, {
      position_lat: lat || 0,
      position_lng: lng || 0,
      position_flr: flr || 0,
      createdAt: new Date().toISOString(),
      status: 0,
      data: JSON.stringify(data),
      poiTypeId: poitype,
      poiLayerId: poilayer,
      poiCityId: poicity,
      owner: owner,
      organization: organization,
      organizationID: organization,
      files: files || null,
      icon: icon,
      shape: 1
    }));
  }

  update = function (id, poicity, poilayer, poitype, lat, lng, flr, data, files, icon) {
    const UpdatePoi = `
         mutation($id: ID!, $position_lat: Float!, $position_lng: Float!, $position_flr: Float!, $createdAt: AWSDateTime, $status: Int, $data: AWSJSON, $poiTypeId: ID, $poiLayerId: ID, $poiCityId: ID, $files: [String], $icon: String, $shape: Int!) {
          updatePoi(input: {
            id: $id,
            position_lat: $position_lat,
            position_lng: $position_lng,
            position_flr: $position_flr,
            createdAt: $createdAt,
            status: $status,
            data: $data,
            poiTypeId: $poiTypeId,
            poiLayerId: $poiLayerId,
            poiCityId: $poiCityId,
            files: $files,
            icon: $icon,
            shape: $shape
           }) {
             id
           }
         }
       `;

    if (data) {
      for (var k in data) {
        if (data.hasOwnProperty(k)) {
          if (!data[k] || typeof (data[k]) == 'undefined' || data[k] == null || data[k] === "")
            delete data[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(UpdatePoi, {
      id: id,
      position_lat: lat,
      position_lng: lng,
      position_flr: flr,
      createdAt: new Date().toISOString(),
      status: '0',
      data: JSON.stringify(data),
      poiTypeId: poitype,
      poiLayerId: poilayer,
      poiCityId: poicity,
      files: files,
      icon: icon,
      shape: 1
    }));
  }

  get = function (id) {
    const getPoi = `
        query {
          getPoi(id:"`+ id + `") {
            id
            position_lat
            position_lng
            position_flr
            data
            status
            files
            organizationID
            owner
            city {
              id
              name
            }
            type {
              id
              name
              structure
            }
            layer, {
              id
              name
              color
            }
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getPoi));
  }

  getWithMaintenances = function (id) {
    const getPoi = `
        query {
          getPoi(id:"`+ id + `") {
            id
            position_lat
            position_lng
            position_flr
            data
            status
            files
            organizationID
            owner
            city {
              id
              name
            }
            type {
              id
              name
              structure
            }
            layer, {
              id
              name
              color
            }
            maintenances {
              items {
                id
                type
                descriptions
                date_create
              }
            }
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getPoi));
  }

  getPoiMaintenances = function (id) {
    const getPoiMaintenances = `
        query {
          getPoiMaintenances(id:"`+ id + `") {
            id
            type
            descriptions
            flags
            notes
            user_create
            date_create
            user_assigned
            date_assigned
            maintenanceactions {
              items {
                id
                type
                status
                level
                description
                info
                user
                date
                planneddate
              }
            }
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getPoiMaintenances));
  }

  delete = function (id) {
    const DeletePoi = `
         mutation($id: ID!) {
          deletePoi(input: {
            id: $id
           }) {
            id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(DeletePoi, {
      id: id
    }));
  }

  all = function () {
    const ListPoi = `
        query {
          listPois(limit:200) {
            items {
              id
              position_lat
              position_lng
              position_flr
            }
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(ListPoi));
  }

  getWithWorks(id: any) {
    const getPoi = `
        query {
          getPoi(id:"`+ id + `") {
            id
            position_lat
            position_lng
            position_flr
            data
            status
            files
            organizationID
            owner
            city {
              id
              name
            }
            type {
              id
              name
              structure
            }
            layer, {
              id
              name
              color
            }
            works {
              items {
                id
                data
              }
            }
          }
        }
      `;
    return this.amplifyService.api().graphql(graphqlOperation(getPoi)) as Promise<any>;
  }

  getPoiWorks(id: any) {
    const getPoiWorks = `
        query {
          getPoiWorks(id:"`+ id + `") {
            id
            data
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getPoiWorks)) as Promise<any>;
  }

}
