import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { NavigatorComponent } from './navigator/navigator.component';
import { RelieveComponent, PoiLayerDialogComponent, DialogPOIExists, StatsDialog } from './relieve/relieve.component';
import { UsersComponent, UserDialogComponent, UserStatsDialogComponent } from './users/users.components';
import { NewMaintenanceDialogComponent, NewMaintenanceActionDialogComponent } from './maintenance/maintenance.component';
import { CitiesComponent } from './cities/cities.component';
import { ImportsComponent } from './imports/imports.component';
import { UserComponent } from './user/user.component';
import { FilesComponent, AddFileDialogComponent, AddFolderDialogComponent } from './files/files.component';
import { ExportsComponent, ExportsDialogComponent } from './exports/exports.component';
import { ActivitiesComponent } from './activities/activities.component';
import { OrganizationsComponent, OrganizationDialogComponent } from './organizations/organizations.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgcFloatButtonModule } from 'ngc-float-button';
import { QRCodeModule } from 'angularx-qrcode';
import { AgGridModule } from 'ag-grid-angular';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { AgmCoreModule } from '@agm/core';

import {
  MatButtonModule, MatToolbarModule, MatSidenavModule, MatAutocompleteModule, MatTabsModule, MatDatepickerModule, MatNativeDateModule,
  MatIconModule, MatListModule, MatGridListModule, MatCardModule, MatMenuModule, MatSliderModule,
  MatFormFieldModule, MatInputModule, MatRadioModule, MatCheckboxModule, MatSelectModule, MatExpansionModule, MatDialogModule,
  MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, MatSnackBarModule,
  MatSlideToggleModule, MatBadgeModule, MatChipsModule
} from '@angular/material';

import { TranslateService } from './translate.service';
import { LoggerService } from './logger.service';
import { ActivitiesService } from './activities.service';
import { LocationsService } from './user/locations.service';
import { GeolocationService } from './user/geolocation.service';
import { TranslatePipe } from './translate.pipe';

import { orderByOrderPipe, orderByNamePipe, orderByLabelPipe, orderByValuePipe, orderByPipe, filterUnique } from './orderbypipe.pipe';
import { TabularComponent, TabularDialog } from './tabular/tabular.component';
import { LocalStorageService } from './localstorage.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VisualizeComponent } from './visualize/visualize.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NewWorkDialogComponent, WorksComponent } from './works/works.component';

const appRoutes: Routes = [
  { path: 'Dashboard', component: DashboardComponent },
  { path: 'Visualize', component: VisualizeComponent },
  { path: 'Maintenance', component: MaintenanceComponent },
  { path: 'Relieve', component: RelieveComponent },
  { path: 'Relieve/:city', component: RelieveComponent },
  { path: 'Relieve/:city/:poi', component: RelieveComponent },
  { path: 'Users', component: UsersComponent },
  { path: 'User', component: UserComponent },
  { path: 'Cities', component: CitiesComponent },
  { path: 'Imports', component: ImportsComponent },
  { path: 'Exports', component: ExportsComponent },
  { path: 'Activities', component: ActivitiesComponent },
  { path: 'Organizations', component: OrganizationsComponent },
  { path: 'Files', component: FilesComponent },
  { path: 'Tabular', component: TabularComponent },
  { path: 'Works', component: WorksComponent },
  { path: '', redirectTo: '/Dashboard', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
    NavigatorComponent,
    RelieveComponent,
    UsersComponent,
    NewMaintenanceDialogComponent,
    NewMaintenanceActionDialogComponent,
    NewWorkDialogComponent,
    UserDialogComponent,
    UserStatsDialogComponent,
    PoiLayerDialogComponent,
    DialogPOIExists,
    AddFileDialogComponent,
    AddFolderDialogComponent,
    CitiesComponent,
    ImportsComponent,
    UserComponent,
    ExportsComponent,
    ExportsDialogComponent,
    ActivitiesComponent,
    OrganizationsComponent,
    OrganizationDialogComponent,
    DynamicFormComponent,
    TranslatePipe,
    orderByOrderPipe,
    orderByNamePipe,
    orderByLabelPipe,
    orderByValuePipe,
    orderByPipe,
    filterUnique,
    FilesComponent,
    TabularComponent,
    TabularDialog,
    StatsDialog,
    DashboardComponent,
    VisualizeComponent,
    MaintenanceComponent,
    WorksComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgcFloatButtonModule,
    //FlexLayoutModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    LayoutModule,
    MatSidenavModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatExpansionModule,
    MatDialogModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatChipsModule,
    RouterModule.forRoot(appRoutes),
    AmplifyAngularModule,
    ReactiveFormsModule,
    FormsModule,
    QRCodeModule,
    StorageServiceModule,
    AgGridModule.withComponents([]),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBvV_JAxkHPhhZkkXPZeK83_u7wBMDXKMU",
      libraries: ['places']
    })
  ],
  providers: [
    AmplifyService,
    TranslateService,
    LocalStorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    MatDatepickerModule,
    LoggerService,
    ActivitiesService,
    LocationsService,
    GeolocationService
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
  ],
  entryComponents: [
    NewMaintenanceDialogComponent,
    NewMaintenanceActionDialogComponent,
    NewWorkDialogComponent,
    UserDialogComponent,
    UserStatsDialogComponent,
    TabularDialog,
    StatsDialog,
    ExportsDialogComponent,
    PoiLayerDialogComponent,
    DialogPOIExists,
    AddFileDialogComponent,
    AddFolderDialogComponent,
    OrganizationDialogComponent
  ]
})
export class AppModule { }

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('it');
}
