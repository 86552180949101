import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";

@Injectable({
  providedIn: 'root'
})
export class LayerService {

  constructor(private amplifyService: AmplifyService) { }

  create = function (name, color, kmlkmz, data, poiLayerCityId, owner, organization) {
    const createPoiLayer = `
         mutation($name: String!, $color: String!, $kmlkmz: String!, $data: AWSJSON, $poiLayerCityId: ID!, $owner: String!, $organization: ID!, $organizationID: String!) {
          createPoiLayer(input: {
            name: $name,
            color: $color,
            kmlkmz: $kmlkmz,
            data: $data,
            poiLayerCityId: $poiLayerCityId,
            owner: $owner,
            poiLayerOrganizationId: $organization,
            organizationID: $organizationID
           }) {
             id
           }
         }
       `;

    if (data) {
      for (var k in data) {
        if (data.hasOwnProperty(k)) {
          if (!data[k] || typeof (data[k]) == 'undefined' || data[k] == null || data[k] === "")
            delete data[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(createPoiLayer, {
      name: name,
      color: color,
      kmlkmz: kmlkmz,
      data: JSON.stringify(data),
      poiLayerCityId: poiLayerCityId,
      owner: owner,
      organization: organization,
      organizationID: organization
    }));
  }

  update = function (id, name, color, kmlkmz, data) {
    const UpdatePoiLayer = `
         mutation($id: ID!, $name: String!, $color: String, $kmlkmz: String, $data: AWSJSON) {
          updatePoiLayer(input: {
            id: $id
            name: $name,
            color: $color,
            kmlkmz: $kmlkmz,
            data: $data
           }) {
             id
           }
         }
       `;

    if (data) {
      for (var k in data) {
        if (data.hasOwnProperty(k)) {
          if (!data[k] || typeof (data[k]) == 'undefined' || data[k] == null || data[k] === "")
            delete data[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(UpdatePoiLayer, {
      id: id,
      name: name,
      color: color,
      kmlkmz: kmlkmz,
      data: JSON.stringify(data),
    }));
  }

  get = function (id) {
    const getPoiLayer = `
        query {
          getPoiLayer(id:"`+ id + `") {
            id
            name
            color
            kmlkmz
            data
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getPoiLayer));
  }

  delete = function (id) {
    const deletePoiLayer = `
         mutation($id: ID!) {
          deletePoiLayer(input: {
            id: $id
           }) {
            id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(deletePoiLayer, {
      id: id
    }));
  }

  all = function () {
    const listPoiLayers = `
        query {
          listPoiLayers {
            items {
              id
              name
              color
              kmlkmz
            }
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(listPoiLayers));
  }
}
