import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSnackBar } from '@angular/material';
import { CitiesService } from '../cities/cities.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap, debounceTime, startWith, map } from 'rxjs/operators';
import { City } from "./city";

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.css']
})
export class CitiesComponent implements OnInit {

  @ViewChild('newCityPanel') newCityPanel: MatSidenav;

  private user;
  private organizations = [];
  private organization;
  private cities = [];
  private bordermarkers = [];
  private map;
  private borderPolygon;
  private currentCity = {
    active: false,
    name: null,
    regione: null,
    provincia: null,
    num_residenti: null,
    superficie: null
  };
  private mapCenter = {
    latitude: 0,
    longitude: 0,
    zoom: 5
  }
  private mapOptions = {
    disableBirdseye: true,
    disableStreetside: false,
    navigationBarMode: 1,
    zoom: 5
  };

  cityForm: FormGroup;
  addCityForm: FormGroup;
  filteredCities: Observable<City[]>;

  constructor(
    private citiesService: CitiesService,
    private snackbar: MatSnackBar,
    private fb: FormBuilder) {

    let self = this;
    /*
        self.userService.getCurrentUser().then(user => {
          self.user = user;
          if (self.user && self.user["type"] && self.user["type"] == "SuperAdmins") {
            self.organizationsService.listOrganizations().then(data => {
              if (data && data.data && data.data.listOrganizationss && data.data.listOrganizationss.items) {
                var allorganizations = data.data.listOrganizationss.items;
                allorganizations.forEach(organization => {
                  if (organization) {
                    self.organizations.push(organization);
                  }
                });
              }
            })
              .catch(err => {
                console.log(err);
              });
          } else {
            self.organizationsService.get(self.user.organization).then(data => {
              if (data && data.data && data.data.getOrganizations) self.organization = data.data.getOrganizations;
            })
              .catch(err => {
                console.log(err);
              });
          }
        }).catch(err => {
          console.log(err);
        });
        */
  }

  ngOnInit() {
    let self = this;
    self.cityForm = self.fb.group({
      userInput: null
    })
    self.addCityForm = self.fb.group({
      city_name: null,
      organization: null
    })

    self.citiesService.searchJSON().subscribe((cities) => {
      self.cities = cities;
    });

    self.filteredCities = self.cityForm.get('userInput').valueChanges.pipe(
        startWith(''),
        map(value => self.filterCities(value))
      );
  }

  private filterCities(city: string): City[] {
    let cities = [];
    if(city && typeof city === 'string')
      this.cities.forEach(element => {
        if (element.name.toLowerCase().indexOf(city.toLowerCase()) !== -1) {
          cities.push(element);
        }
      })
    return cities;
  }

  private poiIcon(marker) {
    return 'data:image/svg+xml;utf-8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="30" height="30" viewBox="0, 0, 30, 30"><g><circle cx="15" cy="15" r="15" stroke="white" stroke-width="3" fill="#fc5c65" /></g></svg>';
  }

  private mapReady(map) {
    let self = this;
    if (map) {
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('cities-map-toptoolbar'));
      self.map = map;
    }
  }

  private onSelectedCity(event) {
    let self = this;
    var city = (event && event.option && event.option.value) ? event.option.value : null;
    if (city) {

      self.currentCity = city;
      var info = city.info;
      self.currentCity.num_residenti = info.num_residenti;
      self.currentCity.superficie = parseFloat(info.superficie).toFixed(2);
      self.currentCity.regione = info.regione;
      self.currentCity.provincia = info.provincia;

      self.currentCity.active = true;

      self.mapCenter.latitude = city.position_lat;
      self.mapCenter.longitude = city.position_lng;
      self.mapCenter.zoom = 13;
      var borders = city.area.toString().replace(/['"]+/g, "").split(" ");
      var bordersCoords = [];

      borders.forEach(border => {
        var borderCord = border.toString().split(",");
        var latitude = parseFloat(borderCord[1]);
        var longitude = parseFloat(borderCord[0]);
        if (borderCord && borderCord.length > 2 && latitude && longitude) bordersCoords.push({ lat: latitude, lng: longitude });
      });
      if (self.borderPolygon) self.borderPolygon.setMap(null);
      self.borderPolygon = new google.maps.Polygon({
        paths: bordersCoords,
        strokeColor: '#fc5c65',
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillColor: '#fc5c65',
        fillOpacity: 0.25
      });
      self.borderPolygon.setMap(self.map);
    }
  }

  displayFn(city: City) {
    if (city) { return city.name; }
  }

  addCity() {
    let self = this;
    self.newCityPanel.open();
  }

  dragMarker(event) {
    let self = this;
    if (event && event.Marker && event.Marker.Metadata && event.Location.latitude && event.Location.longitude) {
      self.bordermarkers[event.Marker.Metadata.index].lat = event.Location.latitude;
      self.bordermarkers[event.Marker.Metadata.index].lng = event.Location.longitude;
      if (self.borderPolygon) self.borderPolygon.setMap(null);
      self.borderPolygon = new google.maps.Polygon({
        paths: self.bordermarkers,
        strokeColor: '#fc5c65',
        strokeOpacity: 0.9,
        strokeWeight: 5,
        fillColor: '#fc5c65',
        fillOpacity: 0.35
      });
      self.borderPolygon.setMap(self.map);
    }
  }

  addMarker() {
    let self = this;
    let current_lat = self.map.center.lat();
    let current_lng = self.map.center.lng();
    self.bordermarkers.push({ lat: current_lat, lng: current_lng });
    if (self.borderPolygon) self.borderPolygon.setMap(null);
    self.borderPolygon = new google.maps.Polygon({
      paths: self.bordermarkers,
      strokeColor: '#fc5c65',
      strokeOpacity: 0.9,
      strokeWeight: 5,
      fillColor: '#fc5c65',
      fillOpacity: 0.35
    });
    self.borderPolygon.setMap(self.map);
  }

  delMarker(index) {
    let self = this;
    if (index && index >= 0) {
      self.bordermarkers.splice(index, 1);
      if (self.borderPolygon) self.borderPolygon.setMap(null);
      self.borderPolygon = new google.maps.Polygon({
        paths: self.bordermarkers,
        strokeColor: '#fc5c65',
        strokeOpacity: 0.9,
        strokeWeight: 5,
        fillColor: '#fc5c65',
        fillOpacity: 0.35
      });
      self.borderPolygon.setMap(self.map);
    }
  }

  save() {
    let self = this;
    let organization_id;
    if (self.organization) {
      organization_id = self.organization.id
    } else {
      organization_id = self.addCityForm.get('organization').value.id;
    }
    let city_name = self.addCityForm.get('city_name').value;
    let city_area = "";
    let lat_average = 0;
    let lng_average = 0;
    self.bordermarkers.forEach(function (bordermarker) {
      lat_average += bordermarker.lat;
      lng_average += bordermarker.lng;
      city_area += bordermarker.lng.toString() + "," + bordermarker.lat.toString() + ",0 ";
    });
    lat_average /= self.bordermarkers.length;
    lng_average /= self.bordermarkers.length;
    console.log(organization_id);
    self.citiesService.create(city_name, lat_average, lng_average, 0, city_area, {}, organization_id)
      .then(data => {
        console.log(data);
        self.snackbar.open('Zone added succesfully', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
        self.newCityPanel.close();
      })
      .catch(err => {
        console.log(err);
        self.newCityPanel.close();
      });
  }

  undo() {
    let self = this;
    self.addCityForm.get('city_name').setValue("");
    self.bordermarkers = [];
    if (self.borderPolygon) self.borderPolygon.setMap(null);
    self.newCityPanel.close();
  }

}
