import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav, MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { LayerService } from '../layer/layer.service';
import { PoiService } from '../poi/poi.service';
import { UserService } from '../users/user.service';
import { CitiesService } from '../cities/cities.service';
import { LocationsService } from '../user/locations.service';
import { LoggerService } from '../logger.service';
import Amplify from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import aws_exports from '../../aws-exports';

Amplify.configure(aws_exports);

import { GeolocationService } from '../user/geolocation.service';
import { TranslateService } from '../translate.service';
import { LocalStorageService } from '../localstorage.service';

export interface PoiLayer {
  id: string,
  name: string;
  color: string;
}
@Component({
  selector: 'app-visualize',
  templateUrl: './visualize.component.html',
  styleUrls: ['./visualize.component.css']
})
export class VisualizeComponent implements OnInit {

  @ViewChild('poiFormPanel') poiFormPanel: MatSidenav;

  private defaultPoiLayer = null;
  private user;
  private cities = [];
  private currentCity = null;
  private pois = [];
  private poilayers = [];
  private poitypes = [];
  private poitypestructure = [];
  private selectedPoiLayers = [];
  private poiData = null;
  private poiDataUpdates = null;
  private poiFiles = [];
  private poiID = '';
  private poiForm = {};
  private poiFormPanelStatus = false;
  private currentPoiType = '';
  private lastInsertedPoi = null;
  private currentPoiLayer = null;
  private map;
  private bounds = null;
  private poiQrcode = null;
  private borderPolygon;
  private poiIconSize = 25;
  private layerLocked = true;
  private poitypeLocked = true;
  private poiAction = null;
  private poiInfowindowdata = null;
  private userGeolocationObj = null;
  private userPreferencies = [];
  private userPositions = [];
  private searchpoi_data = null;
  private currentMarker = {
    index: null,
    position: {
      latitude: 0,
      longitude: 0
    },
    marker: null,
    layer: null
  };
  private mapCenter = {
    latitude: 0,
    longitude: 0,
    zoom: 3
  }
  private mapOptions = {
    disableBirdseye: true,
    disableStreetside: false,
    navigationBarMode: 1,
    zoom: 3,
    showCopyright: false
  };

  loadPoisOnMap() {
    let self = this;
    if (self.poilayers) {
      for (let layer of self.poilayers) {
        layer.poi = {};
        layer.poi.items = [];
        self.loadAllFromLayer(layer, self.user.organization, self.bounds.maxLatitude);
      }
    }
  }

  updateMapBounds(e) {
    this.bounds = {};
    this.bounds.maxLatitude = e.getNorthEast().lat();
    this.bounds.maxLongitude = e.getNorthEast().lng();
    this.bounds.minLatitude = e.getSouthWest().lat();
    this.bounds.minLongitude = e.getSouthWest().lng();
  }

  clearPoisOnMap() {
    let self = this;
    if (self.poilayers) {
      for (let layer of self.poilayers) {
        layer.poi = {};
        layer.poi.items = [];
      }
    }
  }

  poiFormPanelEvent(e) { this.poiFormPanelStatus = e; }

  private mapReady(map) {
    let self = this;
    if (map) {
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('poi-map-toptoolbar-left'));
      map.setOptions({
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_LEFT
        },
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        fullscreenControl: false
      });
      self.map = map;
      self.map.setOptions({ tilt: 0 });
    }
  }

  private resetCurrentMarker() {
    let self = this;
    self.poiFormPanel.close();
    self.poiFiles = [];
    self.poiID = null;
    if (self.isNumeric(self.currentMarker.layer) && self.isNumeric(self.currentMarker.index)) {
      if (self.currentMarker && self.isNumeric(self.currentMarker.layer) && self.isNumeric(self.currentMarker.index) && self.poilayers[self.currentMarker.layer] && self.poilayers[self.currentMarker.layer].poi && self.poilayers[self.currentMarker.layer].poi.items && self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index] && self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].current)
        self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].current = false;
      self.currentMarker.index = null;
      self.currentMarker.marker = null;
      self.currentMarker.position.latitude = 0;
      self.currentMarker.position.longitude = 0;
    }
  }

  private poiIcon(poi) {
    return 'assets/img/markers/102.png';
  }

  private isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  private poiEdit(marker, poi, layer, index) {
    let self = this;
    self.poiAction = "editing";
    if (!self.poiFormPanel.opened && marker && poi && poi.id && self.isNumeric(index) && self.isNumeric(layer)) {
      self.resetCurrentMarker();
      self.poiFiles = [];
      self.poilayers[layer].poi.items[index].current = true;
      self.currentMarker.index = index;
      self.currentMarker.layer = layer;
      self.currentMarker.marker = marker.Marker;
      self.poiFormPanel.open();
      self.poiService.get(poi.id)
        .then(data => {
          try {
            if (data && data.data && data.data.getPoi) {
              var poiData = data.data.getPoi;
              self.poiQrcode = "https://app2.egaia.net/Relieve/" + self.currentCity.id + "/" + poiData.id;
              self.currentPoiLayer = (poiData.layer) ? poiData.layer.id : null;
              self.currentPoiType = (poiData.type) ? poiData.type.id : null;
              self.poiForm = JSON.parse(poiData.type.structure);
              self.poiID = poi.id;
              self.currentMarker.position.latitude = marker.latitude;
              self.currentMarker.position.longitude = marker.longitude;
              poiData.data = JSON.parse(poiData.data);
              console.log(poiData.data);
              if (poiData.data) {
                self.poiData = poiData.data;
                self.poiFiles = poiData.files;
              } else {
                throw "Data of poi not present";
              }
            } else {
              throw "Data of poi not present";
            }
          } catch (e) {
            self.poiFormPanel.open();
            self.resetCurrentMarker();
            self.poiFiles = [];
          }
        })
        .catch(err => {
          self.logger.error(err);
        });
    }
  }

  private loadAllFromLayer(layer: object, organization: string, nextToken?: string) {
    let self = this;
    if (layer && layer["id"]) {
      self.citiesService.getAllPoiFromCityNoData(layer["id"], organization, nextToken)
        .then(data => {
          if (data && data.data && data.data.getPoiLayer && data.data.getPoiLayer.poi && data.data.getPoiLayer.poi.items) {
            layer["poi"]["items"] = layer["poi"]["items"].concat(data.data.getPoiLayer.poi.items);
          }
          if (data && data.data && data.data.getPoiLayer && data.data.getPoiLayer.poi && data.data.getPoiLayer.poi.nextToken) {
            self.loadAllFromLayer(layer, organization, data.data.getPoiLayer.poi.nextToken);
          }
        })
        .catch(err => {
          self.logger.error(err);
        });
    }
  }

  private delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  private paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  private loadAllFromFile(layer: object) {
    let self = this;
    self.httpService.get("https://qstorage160746-prod.s3.eu-west-1.amazonaws.com/public/static/"+layer["id"]+".json").toPromise()
    .then(async (data:any[]) => {
      for(let i=0;i<data.length; i++){
        layer["poi"]["items"].push(data[i]);
        await self.delay(1);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }

  private loadRemote(id, organization, data?: boolean) {
    let self = this;
    self.citiesService.getLayersFromCity(id, organization)
      .then(data => {
        if (data && data.data && data.data.getCities && data.data.getCities.layers && data.data.getCities.layers.items && data.data.getCities.layers.items.length > 0) {
          self.poilayers = data.data.getCities.layers.items;
          for (let layer of data.data.getCities.layers.items) {
            layer.poi = {};
            layer.poi.items = [];
            //self.loadAllFromLayer(layer, organization);
          }
        }
      })
      .catch(err => {
        self.logger.error(err);
      });
  }

  private poiUndo() {
    let self = this;
    self.poiFormPanel.close();
    self.poiFiles = [];
    self.poiID = null;
    self.resetCurrentMarker();
  }

  private loadCity() {
    let self = this, poiNextToken = null;
    try {
      if (self.currentCity && self.user) {
        self.mapCenter.latitude = self.currentCity.position_lat;
        self.mapCenter.longitude = self.currentCity.position_lng;
        self.mapCenter.zoom = 12;
        self.poilayers = [];

        self.loadRemote(self.currentCity.id, self.user.organization, true);

        var borders = self.currentCity.area.toString().replace(/['"]+/g, "").split(" ");
        var bordersCoords = [];

        borders.forEach(border => {
          var borderCord = border.toString().split(",");
          var latitude = parseFloat(borderCord[1]);
          var longitude = parseFloat(borderCord[0]);
          if (borderCord && borderCord.length > 2 && latitude && longitude) bordersCoords.push({ lat: latitude, lng: longitude });
        });
        if (self.borderPolygon) self.borderPolygon.setMap(null);
        self.borderPolygon = new google.maps.Polygon({
          paths: bordersCoords,
          strokeColor: '#3a539b',
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: '#FFF',
          fillOpacity: 0
        });
        self.borderPolygon.setMap(self.map);
      }
      self.defaultPoiLayer = self.localStorageService.get("egaia_defaultlayer_on_" + self.currentCity.id);
    } catch (e) {
      console.log(e);
    }
  }

  changeCity(city) {
    let self = this;
    self.currentCity = (city.value) ? city.value : null;
    self.loadCity();
  }

  selectLayer(e) {
    let self = this;
    if (self.selectedPoiLayers) {
      for (let layer of self.selectedPoiLayers) {
        layer.poi = {};
        layer.poi.items = [];

// check if exists file
self.loadAllFromFile(layer);

        //self.loadAllFromLayer(layer, self.user.organization, null);
      }
    }
  }

  constructor(
    private httpService: HttpClient,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private poiService: PoiService,
    private layerService: LayerService,
    private userService: UserService,
    private citiesService: CitiesService,
    private amplifyService: AmplifyService,
    private logger: LoggerService,
    private locationsService: LocationsService,
    private geolocationService: GeolocationService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog) {

    let self = this;

    self.poiService.subscribe(function (data) {
      if (data && data.value && data.value.data && data.value.data.onCreatePoi && data.value.data.onCreatePoi.id) {
        if (self.lastInsertedPoi != data.value.data.onCreatePoi.id) {
          self.poiService.get(data.value.data.onCreatePoi.id)
            .then(data => {
              try {
                if (data && data.data && data.data.getPoi && data.data.getPoi.city && data.data.getPoi.city.id && data.data.getPoi.organizationID && data.data.getPoi.layer && data.data.getPoi.layer.id) {
                  if (self.currentCity.id == data.data.getPoi.city.id && self.user.organization == data.data.getPoi.organizationID) {
                    self.poilayers.forEach(poilayer => {
                      if (poilayer.id == data.data.getPoi.layer.id) {
                        poilayer.poi.items.push({
                          id: (data.data.getPoi.id) ? data.data.getPoi.id : null,
                          data: (data.data.getPoi.data) ? JSON.parse(data.data.getPoi.data) : null,
                          type: { name: data.data.getPoi.type.name },
                          position_flr: (data.data.getPoi.position_flr) ? data.data.getPoi.position_flr : 0,
                          position_lat: (data.data.getPoi.position_lat) ? data.data.getPoi.position_lat : 0,
                          position_lng: (data.data.getPoi.position_lng) ? data.data.getPoi.position_lng : 0
                        });
                        if (self.userPreferencies && self.userPreferencies["notifynewpoints"] && self.userPreferencies["notifynewpoints"] == 'y') self.snackbar.open(data.data.getPoi.owner + ' added new poi', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
                      }
                    });
                  }
                }
              } catch (e) {
                console.log(e);
              }
            }).catch(err => {
              console.log(err);
            });
        }
      }
    });
  }

  ngOnInit() {
    let self = this;
    let city = self.route.snapshot.paramMap.get('city');
    let selectedCity = null;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        self.cities = self.user.activecities.sort((a, b) => a.name.toString().toLowerCase().localeCompare(b.name.toString().toLowerCase()));
        self.poitypes = [];
        Object.keys(self.user.organizationObject.poiTypes).forEach(key => {
          self.poiService.getType(self.user.organizationObject.poiTypes[key]).then(poiType => {
            self.poitypes.push(poiType.data.getPoiType);
          }).catch(err => {
            console.log(err);
          });
        });
        self.route.params.subscribe(params => {
          self.cities.forEach(city => {
            if (city.name == params.city || city.id == params.city) {
              self.currentCity = city;
              self.loadCity();
            }
          });
        });
      }
    });
  }

  private moveToCurrentPosition() {
    let self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        self.mapCenter.latitude = position.coords.latitude;
        self.mapCenter.longitude = position.coords.longitude;
        self.mapCenter.zoom = 15;
      }, function (error) {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.log("The request to get user location timed out.");
            break;
          default:
            console.log("An unknown error occurred.");
            break;
        }
      });
    }
  }

  private defaultPoiLayerSelected(e) {
    let self = this;
    if (e && e.value) {
      self.localStorageService.set("egaia_defaultlayer_on_" + self.currentCity.id, e.value);
    }
  }

  private getPoiTypeFromId(id: String) {
    for (let poitype of this.poitypes) if (poitype.id.toString() == id.toString()) return poitype;
    return null;
  }

}
