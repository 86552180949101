import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { Observable, BehaviorSubject, from as fromPromise } from 'rxjs';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { CitiesService } from '../cities/cities.service';
import { OrganizationsService } from '../organizations/organizations.service';
import Auth from '@aws-amplify/auth';
import { tap } from 'rxjs/operators';
import { map } from 'async';
import awsmobile from '../../aws-exports.js';
import { graphqlOperation } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public currentuserAvailable: BehaviorSubject<any>;
  public currentUser = null;
  private getCurrentUserObject_promise = null;

  constructor(private amplifyService: AmplifyService, private citiesService: CitiesService, private organizationsService: OrganizationsService) {
    let self = this;
    self.currentuserAvailable = new BehaviorSubject<boolean>(false);

    self.amplifyService.authStateChange$
      .subscribe(authState => {
        if (authState && authState.user) {
          self.getCurrentUser();
          self.currentuserAvailable.next(true);
        }
      });
  }

  signOut() {
    let self = this;
    return fromPromise(Auth.signOut()).pipe(
      tap(() => {
        self.currentUser = null;
      })
    );
  }

  signUp(username, password, activecities, organization) {
    return this.amplifyService.auth().signUp({
      username: username,
      password: password,
      attributes: {
        "email": username,
        "family_name": organization,
        "custom:activecities": JSON.stringify(activecities),
        "custom:organization": organization,
        "custom:preferencies": JSON.stringify({
          "lang": "it",
          "menustatus": "open",
          "ui": "light-theme"
        }),
      },
      validationData: []
    });
  }

  disableUser(user) {
    let email = user.Username;
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });
        var params = {
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email
        };
        cognitoidentityserviceprovider.adminDisableUser(params, function (err, data) {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  changePassword(user, oldpassword, newpassword) {
    let email = user.Username;
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, oldpassword, newpassword);
        })
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
  }

  enableUser(user) {
    let email = user.Username;
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });
        var params = {
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email
        };
        cognitoidentityserviceprovider.adminEnableUser(params, function (err, data) {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  activateUser(user) {
    let email = user.Username;
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });
        var params = {
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email
        };
        cognitoidentityserviceprovider.adminConfirmSignUp(params, function (err, data) {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  public isAuthenticated(): Observable<any> {
    let self = this;
    return fromPromise(new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser().then(user => {
        if (user && user.username) {
          self.getUserType(user["username"]).then(group => {
            if (group && group["Groups"] && group["Groups"].length > 0 && group["Groups"][0]) {
              user["type"] = group["Groups"][0].GroupName;
            }
            user["activecities"] = [];
            self.getUserActiveCities().then(cities => {
              user["activecities"] = cities;
              if (user["attributes"] && user["attributes"]["family_name"]) {
                user["organization"] = user["attributes"]["family_name"];
                self.organizationsService.get(user["organization"]).then(organizationObject => {
                  user["organizationObject"] = organizationObject.data.getOrganizations;
                  self.currentUser = user;
                  self.currentuserAvailable.next(true);
                }).catch(err => console.log(err));
              }
            }).catch(err => console.log(err));
          }).catch(err => console.log(err));
        }
      }).catch(err => console.log(err));
    }));
  }

  getCurrentUser() {
    let self = this;
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user && user.username) {
          self.getUserType(user["username"]).then(group => {
            if (group && group["Groups"] && group["Groups"].length > 0 && group["Groups"][0]) user["type"] = group["Groups"][0].GroupName;
            self.getUserActiveCities().then(cities => {
              user["activecities"] = cities;
              if (user["attributes"] && user["attributes"]["family_name"]) {
                user["organization"] = user["attributes"]["family_name"];
                self.organizationsService.get(user["organization"]).then(organizationObject => {
                  user["organizationObject"] = organizationObject.data.getOrganizations;
                  self.currentUser = user;
                  self.currentuserAvailable.next(true);
                }).catch(err => console.log(err));
              }
            }).catch(err => console.log(err));
          }).catch(err => console.log(err));
        }
      }).catch(err => console.log(err));
  }

  getCurrentuseremail() {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          if (user && user.username) resolve(user.username);
          else reject(null);
        })
        .catch(err => reject(err));
    });
  }

  getUserActiveCities() {
    let self = this;
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          if (user && user.attributes && user.attributes["custom:activecities"]) {
            map(
              JSON.parse(user.attributes["custom:activecities"]),
              function (city, cb) {
                self.citiesService.get(city).then(data => {
                  if (data && data.data && data.data.getCities) cb(null, data.data.getCities);
                  else cb(null, null);
                }).catch(err => {
                  cb(err, null);
                });
              },
              function (err, data) {
                if (err) reject(null);
                else resolve(data);
              });
          } else reject(null);
        })
        .catch(err => reject(err));
    });
  }

  getUserOrganization() {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          if (user && user.attributes && user.attributes["custom:organization"]) resolve(user.attributes["custom:organization"]);
          else reject(null);
        })
        .catch(err => reject(err));
    });
  }

  getUser(email) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });

        var params = {
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email
        };
        cognitoidentityserviceprovider.adminGetUser(params, function (err, data) {
          if (err) {
            reject(err)
          }
          else {
            resolve(data)
          }
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  getUserType(email) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });

        var params = {
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email
        };
        cognitoidentityserviceprovider.adminListGroupsForUser(params, function (err, data) {
          if (err) {
            reject(err)
          }
          else {
            resolve(data)
          }
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  setUserAvatar(email, url) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });
        var params = {
          UserAttributes: [
            {
              Name: 'custom:avatar',
              Value: url.toString()
            }
          ],
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email
        };
        cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function (err, data) {
          if (err) reject(err);
          else resolve(data);
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  setUserPreferencies(email, preferencies) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });
        var params = {
          UserAttributes: [
            {
              Name: 'custom:preferencies',
              Value: JSON.stringify(preferencies)
            }
          ],
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email
        };
        cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function (err, data) {
          if (err) reject(err);
          else resolve(data);
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  setUserType(email, type) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });

        var params = {
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email,
        };
        cognitoidentityserviceprovider.adminListGroupsForUser(params, function (err, data) {
          if (err) console.log(err, err.stack);
          else console.log(data);

          if (data.Groups) {
            data.Groups.forEach(group => {
              var paramsRemoveFrom = {
                GroupName: group.GroupName,
                UserPoolId: awsmobile.aws_user_pools_id,
                Username: email
              };
              cognitoidentityserviceprovider.adminRemoveUserFromGroup(paramsRemoveFrom, function (err, data) {
                if (err) console.log(err, err.stack);
                else console.log(data);
              });

            });
          }

          var params = {
            GroupName: type,
            Username: email,
            UserPoolId: awsmobile.aws_user_pools_id,
          };
          cognitoidentityserviceprovider.adminAddUserToGroup(params, function (err, data) {
            if (err) reject(err);
            else {
              var params = {
                UserAttributes: [
                  {
                    Name: 'custom:role',
                    Value: type
                  }
                ],
                UserPoolId: awsmobile.aws_user_pools_id,
                Username: email
              };
              cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function (err, data) {
                if (err) reject(err);
                else resolve(data);
              });
            }
          });
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  updateUserActiveCities(email, activecities, organization) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });

        var params = {
          UserAttributes: [
            {
              Name: 'family_name',
              Value: organization
            },
            {
              Name: 'custom:activecities',
              Value: JSON.stringify(activecities)
            },
            {
              Name: 'custom:organization',
              Value: organization
            }
          ],
          UserPoolId: awsmobile.aws_user_pools_id,
          Username: email
        };
        cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function (err, data) {
          if (err) reject(err);
          else resolve(data);
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  listUsers(organization: String) {
    let self = this;
    return new Promise((resolve, reject) => {
      Auth.currentCredentials().then(credentials => {
        const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          region: "eu-west-1",
          credentials: Auth.essentialCredentials(credentials)
        });
        var params = {
          UserPoolId: awsmobile.aws_user_pools_id,
          AttributesToGet: [
            "email",
            "custom:organization"
          ],
          Limit: 0
        };
        if (organization) params["Filter"] = "family_name = \"" + organization + "\"";
        cognitoidentityserviceprovider.listUsers(params, (err, data) => {
          if (err) reject(err);
          else resolve(data);
        });
      })
        .catch(err => {
          console.log(err);
        });
    });
  }

  signIn(username, password) {
    return fromPromise(Auth.signIn(username, password))
      .pipe(
        tap(() => this.getCurrentUser())
      );
  }

  session() {
    return this.amplifyService.auth().currentSession();
  }

  statistics = function(user, organization, dateStart, dateEnd){
    const userStatistics = `
        query {
          userStatistics(user:"`+ user + `", organization:"`+ organization + `", dateStart:"`+ dateStart + `",dateEnd:"`+ dateEnd + `")
        }
      `;
      return this.amplifyService.api().graphql(graphqlOperation(userStatistics));
  }

}
