import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import { UserService } from '../users/user.service'

@Injectable({
  providedIn: 'root'
})
export class WorksService {

  constructor(private amplifyService: AmplifyService, private userSevice: UserService) { }

  create = function (data, poiWorksPoiId) {
    const createPoiWorks = `mutation(
        $data: AWSJSON!, 
        $poiWorksPoiId: ID!) {
      createPoiWorks(input: {
            data: $data
            poiWorksPoiId: $poiWorksPoiId
           }) {
             id
             data
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(createPoiWorks, {
      data: JSON.stringify(data),
      poiWorksPoiId: poiWorksPoiId
    }));
  }

  edit = function (id, data) {
    const createPoiWorks = `mutation(
        $id: ID!
        $data: AWSJSON!) {
        updatePoiWorks(input: {
            id: $id
            data: $data
           }) {
             id
             data
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(createPoiWorks, {
      id: id,
      data: JSON.stringify(data)
    }));
  }
  
  delete = function (id) {
    const deletePoiWorks = `
    mutation($id: ID!) {
      deletePoiWorks(input: {
        id: $id
       }) {
         id
       }
     }`;

    return this.amplifyService.api().graphql(graphqlOperation(deletePoiWorks, {
      id: id
    }));
  }

  import = function (file) {
    const createPoiWorksImports = `mutation(
        $file: String!, 
        $date: AWSDateTime!) {
      createPoiWorksImports(input: {
            file: $file
            date: $date
           }) {
             id
             date
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(createPoiWorksImports, {
      file: file,
      date: new Date().toISOString()
    }));
  }

}
